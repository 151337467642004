import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NewsPage from "./components/NewsFeed/NewsPage";
import BlackPanther from "./pages/Guides/guides/duelist/BlackPanther";
import Hela from "./pages/Guides/guides/duelist/Hela";
import Ironman from "./pages/Guides/guides/duelist/Ironman";
import Magik from "./pages/Guides/guides/duelist/Magik";
import Namour from "./pages/Guides/guides/duelist/Namour";
import Punisher from "./pages/Guides/guides/duelist/Punisher";
import ScarletWitch from "./pages/Guides/guides/duelist/ScarletWitch";
import Spiderman from "./pages/Guides/guides/duelist/Spiderman";
import Starlord from "./pages/Guides/guides/duelist/Starlord";
import Storm from "./pages/Guides/guides/duelist/Storm";
import AdamWarlock from "./pages/Guides/guides/strategist/AdamWarlock";
import Loki from "./pages/Guides/guides/strategist/Loki";
import LunaSnow from "./pages/Guides/guides/strategist/LunaSnow";
import Mantis from "./pages/Guides/guides/strategist/Mantis";
import RocketRacoon from "./pages/Guides/guides/strategist/RocketRacoon";
import DoctorStrange from "./pages/Guides/guides/vanguard/DoctorStrange";
import Groot from "./pages/Guides/guides/vanguard/Groot";
import Hulk from "./pages/Guides/guides/vanguard/Hulk";
import Magneto from "./pages/Guides/guides/vanguard/Magneto";
import PeniParker from "./pages/Guides/guides/vanguard/PeniParker";
import Venom from "./pages/Guides/guides/vanguard/Venom";
import GuidesPage from "./pages/Guides/GuidesPage";
import DuelistPage from "./pages/Guides/TypeGuidePages/DuelistPage";
import StrategistPage from "./pages/Guides/TypeGuidePages/StrategistPage";
import VanguardPage from "./pages/Guides/TypeGuidePages/VanguardPage";
import Home from "./pages/Home/Home";
import DuelistTierList from "./pages/TierList/DuelistTierList";
import HeroTierList from "./pages/TierList/HeroTierList";
import StrategistTierList from "./pages/TierList/StrategistTierList";
import VanguardTierList from "./pages/TierList/VanguardTierList";
import Thor from "./pages/Guides/guides/vanguard/Thor";
import Jeff from "./pages/Guides/guides/strategist/Jeff";

const RoutesCollection = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/news/:name" element={<NewsPage />} />
        <Route path="/guides" element={<GuidesPage />} />
        <Route path="/guides">
          <Route path="duelist" element={<DuelistPage />} />
          <Route path="vanguard" element={<VanguardPage />} />
          <Route path="strategist" element={<StrategistPage />} />
          <Route path="spiderman" element={<Spiderman />} />
          <Route path="hela" element={<Hela />} />
          <Route path="blackpanther" element={<BlackPanther />} />
          <Route path="ironman" element={<Ironman />} />
          <Route path="magik" element={<Magik />} />
          <Route path="namor" element={<Namour />} />
          <Route path="punisher" element={<Punisher />} />
          <Route path="hulk" element={<Hulk />} />
          <Route path="doctorstrange" element={<DoctorStrange />} />
          <Route path="magneto" element={<Magneto />} />
          <Route path="venom" element={<Venom />} />
          <Route path="peniparker" element={<PeniParker />} />
          <Route path="groot" element={<Groot />} />
          <Route path="adamwarlock" element={<AdamWarlock />} />
          <Route path="rocketracoon" element={<RocketRacoon />} />
          <Route path="mantis" element={<Mantis />} />
          <Route path="lunasnow" element={<LunaSnow />} />
          <Route path="loki" element={<Loki />} />
          <Route path="scarletwitch" element={<ScarletWitch />} />
          <Route path="storm" element={<Storm />} />
          <Route path="starlord" element={<Starlord />} />
          <Route path="thor" element={<Thor />} />
          <Route path="jeff" element={<Jeff />} />
        </Route>
        <Route path="/tierList" element={<HeroTierList />} />
        <Route path="/tierList">
          <Route path="duelist" element={<DuelistTierList />} />
          <Route path="vanguard" element={<VanguardTierList />} />
          <Route path="strategist" element={<StrategistTierList />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesCollection;
