import NewsList from "../../data/news/NewsList";
import NewsLink from "./NewsLink";
const NewsFeed = () => {
    return <div>
        <div>
            {
            NewsList.map((news, id) => NewsLink(news, id))
        }
        </div>
    </div>
}
export default NewsFeed;