import namor_small_portrait from "../../../assets/images/portrait/small/namor_portrait.webp";
import namor_medium_portrait from "../../../assets/images/portrait/medium/namor_portrait.webp";
import namor_large_portrait from "../../../assets/images/portrait/large/namor_portrait.webp";
// import { ReactComponent as NamorPrimary } from "../../../assets/images/abilities/namor/trident-of-neptune.svg";
import { ReactComponent as NamorLShift } from "../../../assets/images/abilities/namor/blessing-of-the-deep.svg";
import { ReactComponent as NamorQ } from "../../../assets/images/abilities/namor/horn-of-proteus.svg";
import { ReactComponent as NamorE } from "../../../assets/images/abilities/namor/aquatic-domain.svg";
// import { ReactComponent as NamorSecondary } from "../../../assets/images/abilities/namor/wrath-of-the-seven-seas.svg";

const NamorData = {
    name: "Namor",
    small_portrait: namor_small_portrait,
    medium_portrait: namor_medium_portrait,
    large_portrait: namor_large_portrait,
    link: "/guides/namor",
    realName: "Namor McKenzie",
    difficulty: 2,
    primary: "Trident of Neptune",
    Q: "Horn of Proteus",
    QIcon: <NamorQ />,
    LShift: "Blessing of the Deep",
    LShiftIcon: <NamorLShift />,
    E: "Aquatic Domain",
    EIframe: <NamorE />,
    secondary: "Wrath of the Seven Seas",

};
export default NamorData;