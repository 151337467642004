import BlackPantherData from "./BlackPantherData";
import HelaData from "./HelaData";
import IronManData from "./IronManData";
import MagikData from "./MagikData";
import NamourData from "./NamorData";
import PunisherData from "./PunisherData";
import ScarletWitchData from "./ScarletWitchData";
import SpidermanData from "./SpidermanData";
import StarlordData from "./StarlordData";
import StormData from "./StormData";

const DuelistData = [
    IronManData,
    HelaData,
    SpidermanData,
    BlackPantherData,
    NamourData,
    PunisherData,
    StarlordData,
    StormData,
    MagikData,
    ScarletWitchData,
];

export default DuelistData;
