import AdamWarlockData from "./AdamWarlockData";
import LokiData from "./LokiData";
import LunaSnowData from "./LunaSnowData";
import MantisData from "./MantisData";
import RocketRacoonData from "./RocketRacoonData";
import JeffData from "./JeffData";

const StrategistData = [
  AdamWarlockData,
  LokiData,
  LunaSnowData,
  MantisData,
  RocketRacoonData,
    JeffData
];
export default StrategistData;
