import React from "react";
import Navbar from "../NavBar/Navbar";
import Topbar from "../Topbar/Topbar";
import useIsMobile from "../../logic/useIsMobile";
const Base = (props) => {

    return useIsMobile() ? (<><Topbar/>
        <Navbar/>
        <div className="bg-base-300 min-h-screen">{props.children}</div>
    </>) : (<div className="base-background min-h-screen">
        <div className="color_layer min-h-screen w-full pt-10 px-32">
            <Topbar/>
            <Navbar/>
            <div className="bg-base-300 min-h-screen">{props.children}</div>
        </div>
    </div>);
};

export default Base;
