import mantis_small_portrait from "../../../assets/images/portrait/small/mantis_portrait.webp";
import mantis_medium_portrait from "../../../assets/images/portrait/medium/mantis_portrait.webp";
import mantis_large_portrait from "../../../assets/images/portrait/large/mantis_portrait.webp";// import { ReactComponent as MantisPrimary } from "../../../assets/images/abilities/mantis/life-energy-blast.svg";
import { ReactComponent as MantisLShift } from "../../../assets/images/abilities/mantis/spore-slumber.svg";
import { ReactComponent as MantisQ } from "../../../assets/images/abilities/mantis/soul-resurgence.svg";
import { ReactComponent as MantisE } from "../../../assets/images/abilities/mantis/allied-inspiration.svg";
import { ReactComponent as MantisF } from "../../../assets/images/abilities/mantis/natural-anger.svg";
// import { ReactComponent as MantisSecondary } from "../../../assets/images/abilities/mantis/healing-flower.svg";
// import { ReactComponent as MantisPassive } from "../../../assets/images/abilities/mantis/natures-favor.svg";

const MantisData = {
    name: "Mantis",
    small_portrait: mantis_small_portrait,
    medium_portrait: mantis_medium_portrait,
    large_portrait: mantis_large_portrait,
    link: "/guides/mantis",
    affiliations: ["Guardians of the Galaxy"],
    difficulty: 1,
    primary: "Life Energy Blast",
    Q: "Soul Resurgence",
    QIcon: <MantisQ />,
    LShift: "Spore Slumber",
    LShiftIcon: <MantisLShift />,
    E: "Allied Inspiration",
    EIcon: <MantisE />,
    F: "Natural Anger",
    FIcon: <MantisF />,
    secondary: "Healing Flower",
    passive: "Nature's Favor"


};
export default MantisData;