import hela_small_portrait from "../../../assets/images/portrait/small/hela_portrait.webp";
import hela_medium_portrait from "../../../assets/images/portrait/medium/hela_portrait.webp";
import hela_large_portrait from "../../../assets/images/portrait/large/hela_portrait.webp";
import { ReactComponent as HelaPrimary} from "../../../assets/images/abilities/hela/nightsword-thorn.svg";
import { ReactComponent as HelaPassive } from "../../../assets/images/abilities/hela/nastrond-crowstorm.svg";
import { ReactComponent as HelaLShift } from "../../../assets/images/abilities/hela/astral-flock.svg";
import { ReactComponent as HelaQ } from "../../../assets/images/abilities/hela/goddess-of-death.svg";
import { ReactComponent as HelaE } from "../../../assets/images/abilities/hela/soul-drainer.svg";
import { ReactComponent as HelaSecondary } from "../../../assets/images/abilities/hela/piercing-night.svg";
import { ReactComponent as HelaPassive2 } from "../../../assets/images/abilities/hela/hels-descent.svg";

const HelaData = {
    name: "Hela",
    small_portrait: hela_small_portrait,
    medium_portrait: hela_medium_portrait,
    large_portrait: hela_large_portrait,
    link: "/guides/hela",
    realName: "Hela Odinsdottir",
    affiliations: ["Asgardians"],
    difficulty: 3,
    primary: "Nightsword Thorn",
    primaryIcon: <HelaPrimary />,
    Q: "Goddess of Death",
    QIcon: <HelaQ />,
    LShift: "Astral Flock",
    LShiftIcon: <HelaLShift />,
    E: "Soul Drainer",
    EIcon: <HelaE />,
    secondary: "Piercing Night",
    secondaryIcon: <HelaSecondary />,
    passive: "Nastrond Crowstorm",
    passiveIcon: <HelaPassive />,
    passive2: "Hel's Descent",
    passive2Icon: <HelaPassive2 />,

};
export default HelaData;
