import rocket_raccoon_small_portrait from "../../../assets/images/portrait/small/rocketracoon_portrait.webp";
import rocket_raccoon_medium_portrait from "../../../assets/images/portrait/medium/rocketracoon_portrait.webp";
import rocket_raccoon_large_portrait from "../../../assets/images/portrait/large/rocketracoon_portrait.webp";// import { ReactComponent as RocketRaccoonPrimary } from "../../../assets/images/abilities/rocketracoon/bombard-mode.svg";
import { ReactComponent as RocketRaccoonLShift } from "../../../assets/images/abilities/rocketracoon/jetpack-dash.svg";
// import { ReactComponent as RocketRaccoonQ } from "../../../assets/images/abilities/rocketracoon/cya.svg";
// import { ReactComponent as RocketRaccoonE } from "../../../assets/images/abilities/rocketracoon/brb.svg";
// import { ReactComponent as RocketRaccoonSecondary } from "../../../assets/images/abilities/rocketracoon/repair-mode.svg";
import { ReactComponent as RocketRaccoonPassive } from "../../../assets/images/abilities/rocketracoon/wild-crawl.svg";
// import { ReactComponent as RocketRaccoonPassive2 } from "../../../assets/images/abilities/rocketracoon/flying-ace.svg";

const RocketRaccoonData = {
    name: "Rocket Raccoon",
    small_portrait: rocket_raccoon_small_portrait,
    medium_portrait: rocket_raccoon_medium_portrait,
    large_portrait: rocket_raccoon_large_portrait,
    link: "/guides/rocketracoon",
    affiliations: ["Guardians of the Galaxy"],
    difficulty: 1,
    primary: "Bombard Mode",
    secondary: "Repair Mode",
    Q: "C.Y.A.",
    LShift: "Jetpack Dash",
    LShiftIcon: <RocketRaccoonLShift />,
    E: "B.R.B.",
    passive: "Wild Crawl",
    passiveIcon: <RocketRaccoonPassive />,
    passive2: "Flying Ace"

};
export default RocketRaccoonData;