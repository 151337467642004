import {useParams} from 'react-router-dom';
import NewsList from "../../data/news/NewsList";
import getNewsLink from "../../logic/getNewsLink";

const NewsContent = () => {
    const { name } = useParams();

    const link_name = getNewsLink(name)

    let news_data = NewsList.find(x => getNewsLink(x.title) === link_name);

    if (news_data === undefined) {
        return <h1>News Not Found</h1>
    }


    const date = new Date(news_data.date).toLocaleDateString();
    return (
        <div className="card">
            <figure>
                <img src={news_data.banner_image} alt={news_data.title} className="h-32 object-cover" />
            </figure>
            <div className="card-body text-primary">
                <div className="text-opacity-50">{date}</div>
                <h2 className="text-2xl">{news_data.title}</h2>
                <p>{news_data.description}</p>
            </div>
        </div>
    )

}
export default NewsContent;