import spiderman_small_portrait from "../../../assets/images/portrait/small/spiderman_portrait.webp";
import spiderman_medium_portrait from "../../../assets/images/portrait/medium/spiderman_portrait.webp";
import spiderman_large_portrait from "../../../assets/images/portrait/large/spiderman_portrait.webp";
// import { ReactComponent as SpidermanPrimary } from "../../../assets/images/abilities/spiderman/spider-power.svg";
// import { ReactComponent as SpidermanLShift } from "../../../assets/images/abilities/spiderman/web-swing.svg";
import { ReactComponent as SpidermanQ } from "../../../assets/images/abilities/spiderman/spectacular-spin.svg";
import { ReactComponent as SpidermanE } from "../../../assets/images/abilities/spiderman/get-over-here.svg";
import { ReactComponent as SpidermanF } from "../../../assets/images/abilities/spiderman/amazing-combo.svg";
// import { ReactComponent as SpidermanSecondary } from "../../../assets/images/abilities/spiderman/web-cluster.svg";
import { ReactComponent as SpidermanSpace } from "../../../assets/images/abilities/spiderman/thwip-and-flip.svg";

const SpidermanData = {
    name: "Spiderman",
    small_portrait: spiderman_small_portrait,
    medium_portrait: spiderman_medium_portrait,
    large_portrait: spiderman_large_portrait,
    link: "/guides/spiderman",
    realName: "Peter Parker",
    affiliations: ["Spider-Verse", "Avengers"],
    difficulty: 5,
    primary: "Spider-Power",
    secondary: "Web-Cluster",
    Q: "Spectacular Spin",
    QIcon: <SpidermanQ />,
    LShift: "Web-Swing",
    E: "Get Over Here!",
    EIcon: <SpidermanE />,
    F: "Amazing Combo",
    FIcon: <SpidermanF />,
    space: "Thwip and Flip",
    spaceIcon: <SpidermanSpace />,
    passive: "Spider-Sense",

};
export default SpidermanData;