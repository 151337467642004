import loki_small_portrait from "../../../assets/images/portrait/small/loki_portrait.webp";
import loki_medium_portrait from "../../../assets/images/portrait/medium/loki_portrait.webp";
import loki_large_portrait from "../../../assets/images/portrait/large/loki_portrait.webp";import { ReactComponent as LokiPrimary } from "../../../assets/images/abilities/loki/mystical-missile.svg";
import { ReactComponent as LokiLShift } from "../../../assets/images/abilities/loki/regeneration-domain.svg";
import { ReactComponent as LokiQ } from "../../../assets/images/abilities/loki/god-of-mischief.svg";
import { ReactComponent as LokiE } from "../../../assets/images/abilities/loki/doppelganger.svg";
import { ReactComponent as LokiF } from "../../../assets/images/abilities/loki/devious-exchange.svg";
import { ReactComponent as LokiMelee } from "../../../assets/images/abilities/loki/backstab.svg";
import { ReactComponent as LokiSecondary } from "../../../assets/images/abilities/loki/deception.svg";

const LokiData = {
    name: "Loki",
    small_portrait: loki_small_portrait,
    medium_portrait: loki_medium_portrait,
    large_portrait: loki_large_portrait,
    link: "/guides/loki",
    realName: "Loki Laufeyson",
    affiliations: ["Asgardians"],
    difficulty: 4,
    primary: "Mystical Missile",
    primaryIcon: <LokiPrimary />,
    Q: "God of Mischief",
    QIcon: <LokiQ />,
    LShift: "Regeneration Domain",
    LShiftIcon: <LokiLShift />,
    E: "Doppelganger",
    EIcon: <LokiE />,
    F: "Devious Exchange",
    FIcon: <LokiF />,
    melee: "Backstab",
    meleeIcon: <LokiMelee />,
    secondary: "Deception",
    secondaryIcon: <LokiSecondary />,

};
export default LokiData;