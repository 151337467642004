import jeff_small_portrait from "../../../assets/images/portrait/small/jeff_portrait.webp";
import jeff_medium_portrait from "../../../assets/images/portrait/medium/jeff_portrait.webp";
import jeff_large_portrait from "../../../assets/images/portrait/large/jeff_portrait.webp";
import { ReactComponent as JeffPrimary } from "../../../assets/images/abilities/jeff/joyful-splash.svg";
import { ReactComponent as JeffLShift } from "../../../assets/images/abilities/jeff/hide-and-seek.svg";
import { ReactComponent as JeffQ } from "../../../assets/images/abilities/jeff/its-jeff.svg";
import { ReactComponent as JeffE } from "../../../assets/images/abilities/jeff/healing-bubble.svg";
import { ReactComponent as JeffSecondary } from "../../../assets/images/abilities/jeff/aqua-burst.svg";
import { ReactComponent as JeffPassive } from "../../../assets/images/abilities/jeff/oblivious-cuteness.svg";
const JeffData = {
    name: "Jeff the Land Shark",
    small_portrait: jeff_small_portrait,
    medium_portrait: jeff_medium_portrait,
    large_portrait: jeff_large_portrait,
    link: "/guides/jeff",
    difficulty: 2,
    primary: "Joyful Splash",
    primaryIcon: <JeffPrimary />,
    Q: "It's Jeff!",
    QIcon: <JeffQ />,
    LShift: "Hide and Seek",
    LShiftIcon: <JeffLShift />,
    E: "Healing Bubble",
    EIcon: <JeffE />,
    passive: "Oblivious Cuteness",
    passiveIcon: <JeffPassive />,
    secondary: "Aqua Burst",
    secondaryIcon: <JeffSecondary />,

};
export default JeffData;