import storm_small_portrait from "../../../assets/images/portrait/small/storm_portrait.webp";
import storm_medium_portrait from "../../../assets/images/portrait/medium/storm_portrait.webp";
import storm_large_portrait from "../../../assets/images/portrait/large/storm_portrait.webp";

// import { ReactComponent as StormPrimary } from "../../../assets/images/abilities/storm/wind-blade.svg";
import { ReactComponent as StormLShift } from "../../../assets/images/abilities/storm/weather-control.svg";
import { ReactComponent as StormQ } from "../../../assets/images/abilities/storm/omega-hurricane.svg";
import { ReactComponent as StormE } from "../../../assets/images/abilities/storm/goddess-boost.svg";
// import { ReactComponent as StormSecondary } from "../../../assets/images/abilities/storm/bolt-rush.svg";

const StormData = {
    name: "Storm",
    small_portrait: storm_small_portrait,
    medium_portrait: storm_medium_portrait,
    large_portrait: storm_large_portrait,
    link: "/guides/storm",
    realName: "Ororo Munroe",
    affiliations: ["X-Men"], difficulty: 3,
    primary: "Wind Blade",
    Q: "Omega Hurricane",
    QIcon: <StormQ />,
    LShift: "Weather Control",
    LShiftIcon: <StormLShift />,
    E: "Goddess Boost",
    EIcon: <StormE />,
    secondary: "Bolt Rush",

};
export default StormData;