import scarlet_witch_small_portrait from "../../../assets/images/portrait/small/scarletwitch_portrait.webp";
import scarlet_witch_medium_portrait from "../../../assets/images/portrait/medium/scarletwitch_portrait.webp";
import scarlet_witch_large_portrait from "../../../assets/images/portrait/large/scarletwitch_portrait.webp";

// import { ReactComponent as ScarletWitchPrimary } from "../../../assets/images/abilities/scarletwitch/chaos-control.svg";
import { ReactComponent as ScarletWitchLShift } from "../../../assets/images/abilities/scarletwitch/mystical-projection.svg";
import { ReactComponent as ScarletWitchQ } from "../../../assets/images/abilities/scarletwitch/reality-erasure.svg";
import { ReactComponent as ScarletWitchE } from "../../../assets/images/abilities/scarletwitch/dark-seal.svg";
// import { ReactComponent as ScarletWitchSecondary } from "../../../assets/images/abilities/scarletwitch/cythonian-burst.svg";
import { ReactComponent as ScarletWitchSpace } from "../../../assets/images/abilities/scarletwitch/telekinesis.svg";

const ScarletWitchData = {
    name: "Scarlet Witch",
    small_portrait: scarlet_witch_small_portrait,
    medium_portrait: scarlet_witch_medium_portrait,
    large_portrait: scarlet_witch_large_portrait,
    link: "/guides/scarletwitch",
    realName: "Wanda Maximoff",
    affiliations: ["Avengers"],
    difficulty: 1,
    primary: "Chaos Control",
    Q: "Reality Erasure",
    QIcon: <ScarletWitchQ />,
    LShift: "Mystical Projection",
    LShiftIcon: <ScarletWitchLShift />,
    E: "Dark Seal",
    EIcon: <ScarletWitchE />,
    secondary: "Cythonian Burst",
    space: "Telekinesis",
    spaceIcon: <ScarletWitchSpace />,
};
export default ScarletWitchData;