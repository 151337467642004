import TierList from "../../components/Layouts/TierList";
import BlackPantherData from "../../data/HeroData/Duelists/BlackPantherData";
import HelaData from "../../data/HeroData/Duelists/HelaData";
import IronManData from "../../data/HeroData/Duelists/IronManData";
import MagikData from "../../data/HeroData/Duelists/MagikData";
import NamourData from "../../data/HeroData/Duelists/NamorData";
import PunisherData from "../../data/HeroData/Duelists/PunisherData";
import ScarletWitchData from "../../data/HeroData/Duelists/ScarletWitchData";
import SpidermanData from "../../data/HeroData/Duelists/SpidermanData";
import StarlordData from "../../data/HeroData/Duelists/StarlordData";
import StormData from "../../data/HeroData/Duelists/StormData";
import AdamWarlockData from "../../data/HeroData/Strategist/AdamWarlockData";
import JeffData from "../../data/HeroData/Strategist/JeffData";
import LokiData from "../../data/HeroData/Strategist/LokiData";
import LunaSnowData from "../../data/HeroData/Strategist/LunaSnowData";
import MantisData from "../../data/HeroData/Strategist/MantisData";
import RocketRacoonData from "../../data/HeroData/Strategist/RocketRacoonData";
import DoctorStrangeData from "../../data/HeroData/Vanguard/DoctorStrangeData";
import GrootData from "../../data/HeroData/Vanguard/GrootData";
import HulkData from "../../data/HeroData/Vanguard/HulkData";
import MagnetoData from "../../data/HeroData/Vanguard/MagnetoData";
import PeniParkerData from "../../data/HeroData/Vanguard/PeniParkerData";
import ThorData from "../../data/HeroData/Vanguard/ThorData";
import VenomData from "../../data/HeroData/Vanguard/VenomData";

const tierListData = {
  name: "Hero Tier List",
  "S+": [PunisherData],
  S: [MagikData, HelaData, LokiData],
  A: [
    MagnetoData,
    AdamWarlockData,
    VenomData,
    ScarletWitchData,
    DoctorStrangeData,
    StormData,
    LunaSnowData,
    MantisData,
  ],
  B: [
    IronManData,
    SpidermanData,
    GrootData,
    RocketRacoonData,
    HulkData,
    PeniParkerData,
    StarlordData,
    JeffData,
    ThorData,
  ],
  C: [BlackPantherData, NamourData],
};

const HeroTierList = () => {
  return TierList(tierListData);
};
export default HeroTierList;
