import { Link } from 'react-router-dom';
import getNewsLink from "../../logic/getNewsLink";

const NewsLink = (news_data, id ) => {
    const date = new Date(news_data.date).toLocaleDateString();
    return (
        <div key={id} className="text-primary card bordered m-2">
            <figure>
                <img src={news_data.banner_image} alt={news_data.title} className="h-32 object-cover" />
            </figure>
            <div className="card-body">
                <h2 className="text-2xl">{news_data.title}</h2>
                <p>{news_data.description}</p>
                <div className="text-opacity-50">{date}</div>
                <Link to={`/news/${getNewsLink(news_data.title)}`} className="btn btn-primary mt-3">Read Article</Link>
            </div>
        </div>
    )
}

export default NewsLink;