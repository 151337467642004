import Base from "../../components/Layouts/Base";
import HeroTypeLinks from "../../data/HeroTypeLinks";
import {Link} from "react-router-dom";
import spiderman from "../../assets/images/spiderman.webp";
import groot from "../../assets/images/groot.webp";
import loki from "../../assets/images/loki.webp";

const TypeLink = (heroType, id) => {
    const link_to = heroType.link
    let image = null;
    if (heroType.name === "Duelist") {
        image = spiderman;
    } else if (heroType.name === "Strategist") {
        image = loki;
    } else if (heroType.name === "Vanguard") {
        image = groot;
    }
    return (
        <div className="w-2/5 mx-auto p-2 ">
            <Link to={link_to} key={id} className="relative flex flex-col items-center justify-center">
                <div className="grid">
                    <img src={image} alt={heroType.name} className="col-start-1 row-start-1 object-cover rounded-box"/>
                    <div className="col-start-1 row-start-1 text-primary font-bold text-8xl text-center my-auto ability_font">
                        {heroType.name}
                    </div>
                </div>
            </Link>
        </div>
    )
}

const GuidesPage = () => {
    return <Base>
        <div className="flex flex-col justify-evenly pt-10">
            {HeroTypeLinks.map((heroType, id) => {
                return TypeLink(heroType, id)
            })}
        </div>
    </Base>
}
export default GuidesPage;