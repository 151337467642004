import hulk_small_portrait from "../../../assets/images/portrait/small/hulk_portrait.webp";
import hulk_medium_portrait from "../../../assets/images/portrait/medium/hulk_portrait.webp";
import hulk_large_portrait from "../../../assets/images/portrait/large/hulk_portrait.webp";
const HulkData = {
    name: "Hulk",
    small_portrait: hulk_small_portrait,
    medium_portrait: hulk_medium_portrait,
    large_portrait: hulk_large_portrait,
    link: "/guides/hulk",
    realName: "Bruce Banner",
    affiliations: ["Avengers"],
    difficulty: 4,
    primary: "Heavy Blow",


};
export default HulkData;