import doctor_strange_small_portrait from "../../../assets/images/portrait/small/doctorstrange_portrait.webp";
import doctor_strange_medium_portrait from "../../../assets/images/portrait/medium/doctorstrange_portrait.webp";
import doctor_strange_large_portrait from "../../../assets/images/portrait/large/doctorstrange_portrait.webp";import { ReactComponent as DoctorStrangePrimary } from "../../../assets/images/abilities/doctorstrange/daggersofdenak.svg";
import { ReactComponent as DoctorStrangeLShift } from "../../../assets/images/abilities/doctorstrange/cloakoflevitation.svg";
 import { ReactComponent as DoctorStrangeQ } from "../../../assets/images/abilities/doctorstrange/eye-of-agamotto.svg";
import { ReactComponent as DoctorStrangeE } from "../../../assets/images/abilities/doctorstrange/maelstrom-of-madness.svg";
import { ReactComponent as DoctorStrangeF } from "../../../assets/images/abilities/doctorstrange/pentagram-of-farallah.svg";
import { ReactComponent as DoctorStrangeSecondary } from "../../../assets/images/abilities/doctorstrange/shield-of-the-seraphim.svg";
import { ReactComponent as DoctorStrangePassive } from "../../../assets/images/abilities/doctorstrange/price-of-magic.svg";

const DoctorStrangeData = {
    name: "Doctor Strange",
    small_portrait: doctor_strange_small_portrait,
    medium_portrait: doctor_strange_medium_portrait,
    large_portrait: doctor_strange_large_portrait,
    link: "/guides/doctorstrange",
    realName: "Stephen Strange",
    affiliations: ["Avengers"],
    difficulty: 3,
    primary: "Daggers of Denak",
    primaryIcon: <DoctorStrangePrimary />,
    Q: "Eye of Agamotto",
    QIcon: <DoctorStrangeQ />,
    LShift: "Cloak of Levitation",
    LShiftIcon: <DoctorStrangeLShift />,
    E: "Maelstrom of Madness",
    EIcon: <DoctorStrangeE />,
    F: "Pentagram of Farallah",
    FIcon: <DoctorStrangeF />,
    secondary: "Shield of the Seraphim",
    secondaryIcon: <DoctorStrangeSecondary />,
    passive: "Price of Magic",
    passiveIcon: <DoctorStrangePassive />,

};
export default DoctorStrangeData;