import TierList from "../../components/Layouts/TierList";
import IronManData from "../../data/HeroData/Duelists/IronManData";
import MagikData from "../../data/HeroData/Duelists/MagikData";
import HelaData from "../../data/HeroData/Duelists/HelaData";
import PunisherData from "../../data/HeroData/Duelists/PunisherData";
import ScarletWitchData from "../../data/HeroData/Duelists/ScarletWitchData";
import StormData from "../../data/HeroData/Duelists/StormData";
import SpidermanData from "../../data/HeroData/Duelists/SpidermanData";
import StarlordData from "../../data/HeroData/Duelists/StarlordData";
import BlackPantherData from "../../data/HeroData/Duelists/BlackPantherData";
import NamourData from "../../data/HeroData/Duelists/NamorData";

const tierListData = {
  name: "Duelist Tier List",
  "S+" : [PunisherData],
  S: [MagikData, HelaData, PunisherData],
  A: [ScarletWitchData, StormData],
  B: [IronManData, SpidermanData, StarlordData],
  C: [BlackPantherData, NamourData],
  D: []
};

const DuelistTierList = () => {
  return TierList(tierListData);
};

export default DuelistTierList;