import News1 from "./News1";
import News2 from "./News2";
import News3 from "./News3";
import News4 from "./News4";

const NewsList = [
    News4,
    News3,
    News2,
    News1

]
export default NewsList;