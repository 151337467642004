import groot_small_portrait from "../../../assets/images/portrait/small/groot_portrait.webp";
import groot_medium_portrait from "../../../assets/images/portrait/medium/groot_portrait.webp";
import groot_large_portrait from "../../../assets/images/portrait/large/groot_portrait.webp";import { ReactComponent as GrootPrimary } from "../../../assets/images/abilities/groot/vine-strike.svg";
import { ReactComponent as GrootLShift } from "../../../assets/images/abilities/groot/thornlash-wall.svg";
import { ReactComponent as GrootQ } from "../../../assets/images/abilities/groot/strangling-prison.svg";
import { ReactComponent as GrootE } from "../../../assets/images/abilities/groot/ironwood-wall.svg";
import { ReactComponent as GrootSecondary } from "../../../assets/images/abilities/groot/spore-bomb.svg";
import { ReactComponent as GrootPassive } from "../../../assets/images/abilities/groot/flora-colossus.svg";
const GrootData = {
    name: "Groot",
    small_portrait: groot_small_portrait,
    medium_portrait: groot_medium_portrait,
    large_portrait: groot_large_portrait,
    link: "/guides/groot",
    affiliations: ["Guardians of the Galaxy"],
    difficulty: 1,
    primary: "Vine Strike",
    primaryIcon: <GrootPrimary />,
    Q: "Strangling Prison",
    QIcon: <GrootQ />,
    LShift: "Thornlash Wall",
    LShiftIcon: <GrootLShift />,
    E: "Ironwood Wall",
    EIcon: <GrootE />,
    secondary: "Spore Bomb",
    secondaryIcon: <GrootSecondary />,
    passive: "Flora Colossus",
    passiveIcon: <GrootPassive />,

};
export default GrootData;