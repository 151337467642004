import venom_small_portrait from "../../../assets/images/portrait/small/venom_portrait.webp";
import venom_medium_portrait from "../../../assets/images/portrait/medium/venom_portrait.webp";
import venom_large_portrait from "../../../assets/images/portrait/large/venom_portrait.webp";// import { ReactComponent as VenomPrimary } from "../../../assets/images/abilities/venom/dark-predation.svg";
// import { ReactComponent as VenomLShift } from "../../../assets/images/abilities/venom/venom-swing.svg";
// import { ReactComponent as VenomQ } from "../../../assets/images/abilities/venom/feast-of-the-abyss.svg";
// import { ReactComponent as VenomE } from "../../../assets/images/abilities/venom/symbiotic-resilience.svg";
import { ReactComponent as VenomF } from "../../../assets/images/abilities/venom/frenzied-arrival.svg";
import { ReactComponent as VenomSecondary } from "../../../assets/images/abilities/venom/cellular-corrosion.svg";

const VenomData = {
    name: "Venom",
    small_portrait: venom_small_portrait,
    medium_portrait: venom_medium_portrait,
    large_portrait: venom_large_portrait,
    link: "/guides/venom",
    realName: "Eddie Brock",
    affiliations: ["Sinister Six"],
    difficulty: 3,
    primary: "Dark Predation",
    Q: "Feast of the Abyss",
    LShift: "Venom Swing",
    E: "Symbiotic Resilience",
    F: "Frenzied Arrival",
    FIcon: <VenomF />,
    secondary: "Cellular Corrosion",
    secondaryIcon: <VenomSecondary />,
    passive: "Alien Biology"
};
export default VenomData;