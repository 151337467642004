import starlord_small_portrait from "../../../assets/images/portrait/small/starlord_portrait.webp";
import starlord_medium_portrait from "../../../assets/images/portrait/medium/starlord_portrait.webp";
import starlord_large_portrait from "../../../assets/images/portrait/large/starlord_portrait.webp";

// import { ReactComponent as StarLordPrimary } from "../../../assets/images/abilities/starlord/elemental-guns.svg";
import { ReactComponent as StarLordLShift } from "../../../assets/images/abilities/starlord/rocket-propulsion.svg";
import { ReactComponent as StarLordQ } from "../../../assets/images/abilities/starlord/galactic-legend.svg";
import { ReactComponent as StarLordE } from "../../../assets/images/abilities/starlord/blaster-barrage.svg";
// import { ReactComponent as StarLordSecondary } from "../../../assets/images/abilities/starlord/stellar-shift.svg";

const StarLordData = {
    name: "Star Lord",
    small_portrait: starlord_small_portrait,
    medium_portrait: starlord_medium_portrait,
    large_portrait: starlord_large_portrait,
    link: "/guides/starlord",
    realName: "Peter Quill",
    affiliations: ["Guardians of the Galaxy"], difficulty: 2,
    primary: "Elemental Guns",
    Q: "Galactic Legend",
    QIcon: <StarLordQ />,
    LShift: "Rocket Propulsion",
    LShiftIcon: <StarLordLShift />,
    E: "Blaster Barrage",
    EIcon: <StarLordE />,
    secondary: "Stellar Shift"
};
export default StarLordData;