import TierList from "../../components/Layouts/TierList";
import VenomData from "../../data/HeroData/Vanguard/VenomData";
import DoctorStrangeData from "../../data/HeroData/Vanguard/DoctorStrangeData";
import GrootData from "../../data/HeroData/Vanguard/GrootData";
import HulkData from "../../data/HeroData/Vanguard/HulkData";
import PeniParkerData from "../../data/HeroData/Vanguard/PeniParkerData";
import MagnetoData from "../../data/HeroData/Vanguard/MagnetoData";
import ThorData from "../../data/HeroData/Vanguard/ThorData";

const tierListData = {
  name: "Vanguard Tier List",
  S: [VenomData, DoctorStrangeData],
  A: [MagnetoData, GrootData, HulkData, ThorData],
  B: [PeniParkerData],
  C: [],
  D: []
};

const VanguardTierList = () => {
  return TierList(tierListData);
};

export default VanguardTierList;