import Cookies from "js-cookie";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import reddit from "../../assets/images/icons/Reddit_Icon_2Color.svg";
import youtube from "../../assets/images/icons/youtube_social_icon_red.webp";
import Banner from "../Branding/Banner";

const Topbar = () => {
    const handleThemeChange = (e) => {
        const theme = e.target.checked ? "dark" : "light";
        Cookies.set("theme", theme);
        document.documentElement.setAttribute("data-theme", theme);
    };

    useEffect(() => {
        const theme = Cookies.get("theme") || "light";
        document.documentElement.setAttribute("data-theme", theme);
    }, []);

    const social_enables = false;

    const socials = (
        <>
            <div>
                <a
                    href="https://www.youtube.com/@rivalsguide"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={youtube}
                        alt={"youtube"}
                        className="w-10 translate-y-1/5 mx-6"
                    />
                </a>
            </div>
            <div>
                <a
                    href="https://www.reddit.com/r/rivalsguide/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={reddit}
                        alt={"reddit"}
                        className="w-10 translate-y-1/5 mx-6"
                    />
                </a>
            </div>
        </>)

    return (<div className="flex flex-col">
            <div className="navbar bg-base-300 rounded-t-box">
                <div className="flex-1 px-2 lg:flex-none">
                    <Link to="/" className="w-full h-full">
                        <Banner/>
                    </Link>
                    {social_enables ? socials : null}
                </div>
                <div className="flex flex-1 justify-end px-2">
                    <div className="flex text-primary items-stretch">
                        <label className="swap swap-rotate">
                            <span className="sr-only">Home page</span>
                            <input
                                type="checkbox"
                                className="theme-controller"
                                value="dark"
                                onChange={handleThemeChange}
                            />
                            <svg
                                className="swap-off h-10 w-10 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
                            </svg>
                            <svg
                                className="swap-on h-10 w-10 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/>
                            </svg>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Topbar;
