import magik_small_portrait from "../../../assets/images/portrait/small/magik_portrait.webp";
import magik_medium_portrait from "../../../assets/images/portrait/medium/magik_portrait.webp";
import magik_large_portrait from "../../../assets/images/portrait/large/magik_portrait.webp";// import { ReactComponent as MagikPrimary } from "../../../assets/images/abilities/magik/soulsword.svg";
import { ReactComponent as MagikLShift } from "../../../assets/images/abilities/magik/stepping-discs.svg";
import { ReactComponent as MagikQ } from "../../../assets/images/abilities/magik/darkchild.svg";
import { ReactComponent as MagikE } from "../../../assets/images/abilities/magik/umbral-incursion.svg";
import { ReactComponent as MagikSecondary } from "../../../assets/images/abilities/magik/magik-slash.svg";
// import { ReactComponent as MagikSecondary2 } from "../../../assets/images/abilities/magik/demons-rage.svg";
import { ReactComponent as MagikPrimary2 } from "../../../assets/images/abilities/magik/eldrich-whirl.svg";

const MagikData = {
    name: "Magik",
    small_portrait: magik_small_portrait,
    medium_portrait: magik_medium_portrait,
    large_portrait: magik_large_portrait,
    link: "/guides/magik",
    realName: "Illyana Rasputina",
    difficulty: 3,
    primary: "Soulsword",
    Q: "Darkchild",
    QIcon: <MagikQ />,
    LShift: "Stepping Discs",
    LShiftIcon: <MagikLShift />,
    E: "Umbral Incursion",
    EIcon: <MagikE />,
    primary2: "Eldrich Whirl",
    priamry2Icon: <MagikPrimary2 />,
    secondary: "Magik Slash",
    secondaryIcon: <MagikSecondary />,
    secondary2: "Demon's Rage",
    passive: "Limbo's Might",


};
export default MagikData;
