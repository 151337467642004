import luna_snow_small_portrait from "../../../assets/images/portrait/small/lunasnow_portrait.webp";
import luna_snow_medium_portrait from "../../../assets/images/portrait/medium/lunasnow_portrait.webp";
import luna_snow_large_portrait from "../../../assets/images/portrait/large/lunasnow_portrait.webp";import { ReactComponent as LunaSnowPrimary } from "../../../assets/images/abilities/lunasnow/light-dark-ice.svg";
import { ReactComponent as LunaSnowQ } from "../../../assets/images/abilities/lunasnow/fate-of-both-worlds.svg";
import { ReactComponent as LunaSnowLShift } from "../../../assets/images/abilities/lunasnow/ice-arts.svg";
import { ReactComponent as LunaSnowE } from "../../../assets/images/abilities/lunasnow/share-the-stage.svg";
import { ReactComponent as LunaSnowSecondary } from "../../../assets/images/abilities/lunasnow/absolute-zero.svg";
import { ReactComponent as LunaSnowPassive } from "../../../assets/images/abilities/lunasnow/cryo-heart.svg";

const LunaSnowData = {
    name: "Luna Snow",
    small_portrait: luna_snow_small_portrait,
    medium_portrait: luna_snow_medium_portrait,
    large_portrait: luna_snow_large_portrait,
    link: "/guides/lunasnow",
    realName: "Seol Hee",
    difficulty: 2,
    primary: "Light & Dark Ice",
    primaryIcon: <LunaSnowPrimary />,
    Q: "Fate of Both Worlds",
    QIcon: <LunaSnowQ />,
    LShift: "Ice Arts",
    LShiftIcon: <LunaSnowLShift />,
    E: "Share the Stage",
    EIcon: <LunaSnowE />,
    secondary: "Absolute Zero",
    secondaryIcon: <LunaSnowSecondary />,
    passive: "Cryo Heart",
    passiveIcon: <LunaSnowPassive />,
    passive2: "Smooth Skate"

};
export default LunaSnowData;