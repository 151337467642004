import black_panther_large from "../../../assets/images/portrait/large/blackpanther_portrait.webp";
import black_panther_medium from "../../../assets/images/portrait/medium/blackpanther_portrait.webp";
import black_panther_small from "../../../assets/images/portrait/small/blackpanther_portrait.webp";

import { ReactComponent as BlackPantherPrimary } from "../../../assets/images/abilities/blackpanther/vibranium-claws.svg";
import { ReactComponent as BlackPantherQ } from "../../../assets/images/abilities/blackpanther/basts-descent.svg";
import { ReactComponent as BlackPantherLShift } from "../../../assets/images/abilities/blackpanther/sprint-rend.svg";
import { ReactComponent as BlackPantherE } from "../../../assets/images/abilities/blackpanther/spinning-kick.svg";
import { ReactComponent as BlackPantherSpace } from "../../../assets/images/abilities/blackpanther/subtle-step.svg";
import { ReactComponent as BlackPantherSecondary } from "../../../assets/images/abilities/blackpanther/spear-toss.svg";
import { ReactComponent as BlackPantherPassive } from "../../../assets/images/abilities/blackpanther/panthers-cunning.svg";

const BlackPantherData = {
    name: "Black Panther",
    large_portrait: black_panther_large,
    medium_portrait: black_panther_medium,
    small_portrait: black_panther_small,
    link: "/guides/blackpanther",
    realName: "T'Challa",
    affiliations: ["Avengers", "Wakanda"],
    difficulty: 4,
    primary: "Vibranium Claws",
    primaryIcon: <BlackPantherPrimary />,
    Q: "Bast's Descent",
    QIcon: <BlackPantherQ />,
    LShift: "Sprint Rend",
    LShiftIcon: <BlackPantherLShift />,
    E: "Spinning Kick",
    EIcon: <BlackPantherE />,
    Space: "Subtle Step",
    SpaceIcon: <BlackPantherSpace />,
    secondary: "Spear Toss",
    secondaryIcon: <BlackPantherSecondary />,
    passive: "Panther's Cunning",
    passiveIcon: <BlackPantherPassive />

};
export default BlackPantherData;
