import adam_warlock_small_portrait from "../../../assets/images/portrait/small/adamwarlock_portrait.webp";
import adam_warlock_medium_portrait from "../../../assets/images/portrait/medium/adamwarlock_portrait.webp";
import adam_warlock_large_portrait from "../../../assets/images/portrait/large/adamwarlock_portrait.webp";


import { ReactComponent as AdamWarlockPrimary } from "../../../assets/images/abilities/adamwarlock/quantum-magic.svg";
import { ReactComponent as AdamWarlockLShift } from "../../../assets/images/abilities/adamwarlock/soul-bond.svg";
import { ReactComponent as AdamWarlockQ } from "../../../assets/images/abilities/adamwarlock/karmic-revival.svg";
import { ReactComponent as AdamWarlockE } from "../../../assets/images/abilities/adamwarlock/avatar-life-stream.svg";
import { ReactComponent as AdamWarlockSecondary } from "../../../assets/images/abilities/adamwarlock/cosmic-cluster.svg";

const AdamWarlockData = {
    name: "Adam Warlock",
    small_portrait: adam_warlock_small_portrait,
    medium_portrait: adam_warlock_medium_portrait,
    large_portrait: adam_warlock_large_portrait,
    link: "/guides/adamwarlock",
    affiliations: ["Guardians of the Galaxy"],
    difficulty: 2,
    primary: "Quantum Magic",
    primaryIcon: <AdamWarlockPrimary />,
    Q: "Karmic Revival",
    QIcon: <AdamWarlockQ />,
    LShift: "Soul Bond",
    LShiftIcon: <AdamWarlockLShift />,
    E: "Avatar Life Stream",
    EIcon: <AdamWarlockE />,
    secondary: "Cosmic Cluster",
    secondaryIcon: <AdamWarlockQ />,
    passive: "Regenerative Cocoon",
    passiveIcon: <AdamWarlockSecondary />

};
export default AdamWarlockData;