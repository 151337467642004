import React from "react";
import Base from "../../components/Layouts/Base";
import NewsFeed from "../../components/NewsFeed/NewsFeed";

const Home = () => {
    return (
        <Base>
            <NewsFeed/>
        </Base>
    )
}
export default Home;