import TierList from "../../components/Layouts/TierList";
import LokiData from "../../data/HeroData/Strategist/LokiData";
import LunaSnowData from "../../data/HeroData/Strategist/LunaSnowData";
import MantisData from "../../data/HeroData/Strategist/MantisData";
import RocketRacoonData from "../../data/HeroData/Strategist/RocketRacoonData";
import AdamWarlockData from "../../data/HeroData/Strategist/AdamWarlockData";
import JeffData from "../../data/HeroData/Strategist/JeffData";

const tierListData = {
  name: "Strategist Tier List",
  S: [LokiData, LunaSnowData],
  A: [MantisData, AdamWarlockData, JeffData],
  B: [RocketRacoonData],
  C: [],
  D: []
};

const StrategistTierList = () => {
  return TierList(tierListData);
};

export default StrategistTierList;