import magneto_small_portrait from "../../../assets/images/portrait/small/magneto_portrait.webp";
import magneto_medium_portrait from "../../../assets/images/portrait/medium/magneto_portrait.webp";
import magneto_large_portrait from "../../../assets/images/portrait/large/magneto_portrait.webp";

import { ReactComponent as MagnetoPrimary } from "../../../assets/images/abilities/magneto/iron-volley.svg";
import { ReactComponent as MagnetoLShift } from "../../../assets/images/abilities/magneto/metallic-curtain.svg";
import { ReactComponent as MagnetoQ } from "../../../assets/images/abilities/magneto/meteor-m.svg";
import { ReactComponent as MagnetoE } from "../../../assets/images/abilities/magneto/metal-bulwark.svg";
import { ReactComponent as MagnetoF } from "../../../assets/images/abilities/magneto/iron-bulwark.svg";
import { ReactComponent as MagnetoSecondary } from "../../../assets/images/abilities/magneto/mag-cannon.svg";
import { ReactComponent as MagnetoPassive } from "../../../assets/images/abilities/magneto/magnetic-descent.svg";

const MagnetoData = {
    name: "Magneto",
    small_portrait: magneto_small_portrait,
    medium_portrait: magneto_medium_portrait,
    large_portrait: magneto_large_portrait,
    link: "/guides/magneto",
    realName: "Erik Lehnsherr",
    affiliations: ["Brotherhood of Mutants"],
    difficulty: 2,
    primary: "Iron Volley",
    primaryIcon: <MagnetoPrimary />,
    Q: "Meteor M",
    QIcon: <MagnetoQ />,
    LShift: "Metallic Curtain",
    LShiftIcon: <MagnetoLShift />,
    E: "Metal Bulwark",
    EIcon: <MagnetoE />,
    F: "Iron Bulwark",
    FIcon: <MagnetoF />,
    secondary: "Mag-Cannon",
    secondaryIcon: <MagnetoSecondary />,
    passive: "Magnetic Descent",
    passiveIcon: <MagnetoPassive />,

};
export default MagnetoData;