import { Link } from "react-router-dom";
import Base from "./Base";
import useIsMobile from "../../logic/useIsMobile";

const TierList = (tierListData) => {
  const rows = ["S+", "S", "A", "B", "C", "D", "F"];

  const isMobile = useIsMobile();

  function tierListRow(row) {
    return tierListData[row] ? (
      <tr className="border border-primary">
        <td className="border border-primary text-primary text-4xl font-bold px-6">
          {row}
        </td>
        <td className="flex flex-row justify-start flex-wrap gap-2">
          {tierListData[row].map((hero) => heroIcon(hero))}
        </td>
      </tr>
    ) : (
      <></>
    );
  }

  function heroIcon(hero) {

    return (
      <Link to={hero.link}>
          <img
            className="object-scale-down rounded-box aspect-[9/16]"
            src={isMobile ? hero.small_portrait: hero.medium_portrait}
            alt={hero.name}
          />
      </Link>
    );
  }

  return (
    <Base>
      <div className="hero">
        <div className="w-full flex flex-col justify-center pt-10 mx-auto border-base-300 border-4">
          <div className="flex flex-row justify-center my-10">
            <h1 className="text-primary text-4xl font-bold">
              {tierListData.name}
            </h1>
          </div>
          <table className="w-5/6 mx-auto bg-primary-content">
            {rows.map((row) => tierListRow(row))}
          </table>
        </div>
      </div>
    </Base>
  );
};
export default TierList;
