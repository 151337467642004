import {Link} from "react-router-dom";
import useIsMobile from "../../logic/useIsMobile";
import GuideTypeLayoutMobile from "./GuideTypeLayoutMobile";

const GuideTypeLayout = (heroes) => {

    if (useIsMobile()) {
        return GuideTypeLayoutMobile(heroes)
    }

    return <div className="flex flex-row justify-center flex-wrap h-full gap-10 pt-10">
        {
            heroes.map((hero, id) => {
                return <div key={id} className="">
                    <Link to={hero.link}>
                        <img src={hero.large_portrait} alt={hero.name} className="object-scale-down shadow-xl rounded-box w-full aspect-[9/16]"/>
                    </Link>
                </div>
            })
        }
    </div>
}

export default GuideTypeLayout