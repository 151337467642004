import thor_small_portrait from "../../../assets/images/portrait/small/thor_portrait.webp";
import thor_medium_portrait from "../../../assets/images/portrait/medium/thor_portrait.webp";
import thor_large_portrait from "../../../assets/images/portrait/large/thor_portrait.webp";

// import { ReactComponent as ThorPrimary } from '../../../assets/images/abilities/thor/god-of-thunder.svg';
// import { ReactComponent as ThorLShift } from '../../../assets/images/abilities/thor/storm-surge.svg';
// import { ReactComponent as ThorQ } from '../../../assets/images/abilities/thor/mjolnir-bash.svg';
// import { ReactComponent as ThorE } from '../../../assets/images/abilities/thor/lightning-realm.svg';
import { ReactComponent as ThorF } from '../../../assets/images/abilities/thor/awakening-rune.svg';
import { ReactComponent as ThorSecondary } from '../../../assets/images/abilities/thor/hammer-throw.svg';

const ThorData = {
    name: 'Thor',
    small_portrait: thor_small_portrait,
    medium_portrait: thor_medium_portrait,
    large_portrait: thor_large_portrait,
    link: "/guides/thor",
    difficulty: 2,
    primary: "Mjolnir Bash",
    Q: "God of Thunder",
    LShift: "Storm Surge",
    E: "Lightning Realm",
    F: "Awakening Rune",
    FIcon: <ThorF />,
    secondary: "Hammer Throw",
    secondaryIcon: <ThorSecondary />,
    passive: "Thorforce"
}
export default ThorData;