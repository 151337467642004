import DoctorStrangeData from "./DoctorStrangeData";
import GrootData from "./GrootData";
import HulkData from "./HulkData";
import MagnetoData from "./MagnetoData";
import PeniParkerData from "./PeniParkerData";
import VenomData from "./VenomData";
import ThorData from "./ThorData";

const VanguardData = [
  DoctorStrangeData,
  GrootData,
  HulkData,
  MagnetoData,
  PeniParkerData,
  VenomData,
    ThorData
];
export default VanguardData;
