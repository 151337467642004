const GuideAbilityLayout = (data) => {
    if (data === undefined) {
        return;
    }

    const tableRow = (data, id) => {
        if (data.value === undefined || data.value === null) {
            return;
        }
        console.log(data.nameIcon)
        return (
            <tr key={id}>
                <td className="ability_font text-4xl font-bold">{data.name.toUpperCase()}</td>
                <td>
                    <div className="mx-auto w-fit">{data.icon}</div>
                </td>
                <td>{data.value}</td>
            </tr>
        );
    };

    return (
        <table className="bg-primary-content border border-primary w-full box-border text-center">
            {data.map((element, id) => tableRow(element, id))}
        </table>
    );
};
export default GuideAbilityLayout;
