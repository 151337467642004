import GuideAbilityLayout from "./GuideAbilityLayout";
import {ReactComponent as primaryIcon} from "../../assets/images/icons/left_click.svg";
const GuideContent = (guideData) => {
  const data_content = [
    {
      name: "Primary Attack",
      nameIcon: primaryIcon,
      value: guideData.primary,
      icon: guideData.primaryIcon,
    },
    {
      name: "Secondary Attack",
      value: guideData.secondary,
      icon: guideData.secondaryIcon,
    },
    { name: "Q", value: guideData.Q, icon: guideData.QIcon },
    { name: "E", value: guideData.E, icon: guideData.EIcon },
    { name: "Shift", value: guideData.LShift, icon: guideData.LShiftIcon },
    { name: "F", value: guideData.F, icon: guideData.FIcon },
    { name: "Space", value: guideData.space, icon: guideData.spaceIcon },
    { name: "Passive", value: guideData.passive, icon: guideData.passiveIcon },
    {
      name: "Passive",
      value: guideData.passive2,
      icon: guideData.passive2Icon,
    },
  ];

  return (
    <div className="text-primary font-bold fridge">
      <h2 className="text-3xl font-bold text-center pb-4">Abilities</h2>
      <div className="mx-auto w-4/5">
        {GuideAbilityLayout(data_content)}
        {guideData.bio ? (
          <div className="text-3xl font-bold text-center">Biography</div>
        ) : null}
      </div>
    </div>
  );
};
export default GuideContent;
