import React from "react";
import Base from "./Base";
import GuideContent from "./GuideContent";

const GuideLayout = (guideData) => {
  return (
    <Base>
      <div className="pt-10"></div>
      <div className="flex flex-col w-5/6 mx-auto border border-primary min-h-screen">
        <h1 className="text-5xl text-primary fridge font-bold text-center mt-10 pb-4">
          {guideData.name}
        </h1>

        <div className="flex flex-row mt-10 justify-between">
          <div className="flex flex-col w-3/4">{GuideContent(guideData)}</div>
          <div className="flex flex-col justify-start text-center">
            <div className=" p-4 justify-center">
              <img
                className="rounded-box box shadow-xl object-scale-down mx-auto"
                src={guideData.large_portrait}
                alt={guideData.name}
              />
              <div className="mt-4 text-primary">
                {guideData.realName ? (
                  <h2> Real Name: {guideData.realName}</h2>
                ) : (
                  <h2>Name: {guideData.name}</h2>
                )}
                {guideData.affiliations ? (
                  <h2> Affiliations: {guideData.affiliations.join(", ")}</h2>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default GuideLayout;
