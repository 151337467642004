import {Link} from "react-router-dom";

const GuideTypeLayoutMobile = (heroes) => {
    return <div className="flex flex-row justify-center flex-wrap h-full pt-10">
        {
            heroes.map((hero, id) => {
                return <div key={id} className="p-4">
                    <Link to={hero.link}>
                        <img src={hero.medium_portrait} alt={hero.name} className="w-full shadow-xl object-scale-down rounded-box aspect-[9/16]"/>
                    </Link>
                </div>
            })
        }
    </div>
}
export default GuideTypeLayoutMobile
