const Banner = () => {
    return (<><span aria-hidden="true"><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="100%" viewBox="0 0 794.000000 234.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                            className="bg-base-300 rounded-t-box text-primary">

            <g transform="translate(0.000000,234.000000) scale(0.100000,-0.100000)"
               fill="var(--fallback-p,oklch(var(--p)/var(--tw-text-opacity)))">
                <path d="M443 1844 c-73 -78 -133 -146 -133 -151 0 -6 43 -40 95 -78 55 -40
91 -73 88 -79 -4 -6 -36 -46 -71 -88 l-63 -78 70 -80 c180 -203 305 -410 376
-623 l35 -102 0 55 c0 223 -71 752 -137 1015 -32 130 -106 340 -121 348 -4 2
-67 -61 -139 -139z m177 -85 c57 -177 127 -559 145 -794 l7 -80 -54 90 c-83
139 -146 224 -255 348 l-42 48 75 91 75 90 -95 70 c-53 38 -96 72 -96 76 0 4
41 51 92 106 l91 98 14 -25 c7 -14 26 -67 43 -118z"/>
                <path d="M1227 1922 c-108 -241 -202 -758 -232 -1277 l-5 -100 25 85 c59 198
223 475 388 654 l77 85 -71 85 c-38 47 -68 90 -65 94 3 5 46 37 95 72 50 35
91 67 91 70 0 9 -261 290 -270 290 -4 0 -19 -26 -33 -58z m233 -223 c0 -3 -43
-37 -95 -74 -52 -38 -95 -71 -95 -74 0 -3 32 -44 70 -89 39 -46 70 -86 70 -89
0 -2 -36 -48 -81 -101 -93 -111 -141 -179 -216 -307 l-54 -90 6 50 c46 422
115 766 187 935 l20 45 93 -100 c52 -55 94 -103 95 -106z"/>
                <path d="M720 1885 c0 -47 3 -85 7 -85 5 0 50 14 100 31 l93 31 93 -31 c50
-17 95 -31 100 -31 4 0 7 38 7 85 0 47 -2 85 -5 85 -3 0 -47 -21 -100 -47
l-95 -47 -67 33 c-38 19 -83 40 -100 47 l-33 14 0 -85z m74 -4 c3 -5 0 -11 -8
-14 -15 -6 -26 1 -26 15 0 11 27 10 34 -1z m276 -5 c0 -9 -7 -12 -21 -9 -16 4
-17 8 -7 14 19 13 28 11 28 -5z"/>
                <path d="M1920 1235 l0 -315 75 0 75 0 0 85 0 85 39 0 38 0 35 -82 35 -83 76
-3 76 -3 -35 87 -35 87 24 21 c42 35 47 58 47 201 0 229 -7 235 -275 235
l-175 0 0 -315z m291 165 c16 -9 19 -22 19 -78 0 -40 -5 -73 -12 -80 -7 -7
-40 -12 -80 -12 l-68 0 0 90 0 90 61 0 c33 0 69 -5 80 -10z"/>
                <path d="M2500 1235 l0 -315 70 0 70 0 0 315 0 315 -70 0 -70 0 0 -315z"/>
                <path d="M2780 1545 c0 -3 38 -143 85 -311 47 -168 85 -307 85 -310 0 -2 33
-4 73 -4 l72 0 84 303 c46 166 86 308 89 315 3 9 -14 12 -67 12 l-70 0 -20
-67 c-11 -38 -34 -121 -52 -186 -18 -65 -36 -112 -39 -105 -4 7 -28 91 -55
186 l-47 172 -69 0 c-38 0 -69 -2 -69 -5z"/>
                <path d="M3456 1533 c-23 -76 -166 -596 -166 -604 0 -5 31 -9 69 -9 l69 0 11
45 11 46 83 -3 82 -3 9 -42 9 -43 72 0 72 0 -48 173 c-26 94 -65 235 -87 312
l-39 140 -71 3 c-60 2 -71 0 -76 -15z m97 -313 c10 -33 17 -62 17 -65 0 -6
-65 -7 -72 0 -6 6 25 125 33 125 3 0 13 -27 22 -60z"/>
                <path d="M3910 1235 l0 -315 190 0 190 0 0 70 0 70 -120 0 -120 0 0 245 0 245
-70 0 -70 0 0 -315z"/>
                <path d="M4482 1535 c-47 -21 -62 -64 -62 -180 0 -164 28 -195 180 -195 l80 0
0 -50 0 -50 -130 0 -130 0 0 -70 0 -70 155 0 c227 0 245 15 245 197 0 155 -27
184 -170 191 l-91 4 3 47 3 46 128 3 127 3 0 69 0 70 -152 0 c-110 -1 -162 -5
-186 -15z"/>
                <path d="M5012 1535 c-56 -24 -62 -55 -62 -302 0 -313 0 -313 225 -313 158 0
181 7 207 60 15 29 18 62 18 183 l0 147 -110 0 -110 0 0 -75 0 -75 40 0 40 0
0 -50 0 -50 -85 0 -85 0 2 173 3 172 153 3 152 3 0 69 0 70 -177 0 c-132 -1
-187 -5 -211 -15z"/>
                <path d="M5530 1285 c0 -294 5 -321 59 -349 23 -12 62 -16 166 -16 104 0 143
4 166 16 54 28 59 55 59 349 l0 265 -70 0 -70 0 0 -245 0 -245 -85 0 -85 0 0
245 0 245 -70 0 -70 0 0 -265z"/>
                <path d="M6110 1235 l0 -315 70 0 70 0 0 315 0 315 -70 0 -70 0 0 -315z"/>
                <path d="M6390 1235 l0 -315 186 0 186 0 34 34 34 34 0 239 c0 263 -3 277 -60
305 -29 15 -63 18 -207 18 l-173 0 0 -315z m288 163 c8 -8 12 -60 12 -169 0
-180 6 -169 -96 -169 l-64 0 0 175 0 175 68 0 c40 0 73 -5 80 -12z"/>
                <path d="M6970 1235 l0 -315 195 0 195 0 0 70 0 70 -125 0 -125 0 0 50 0 50
90 0 90 0 0 75 0 75 -90 0 -90 0 0 50 0 50 125 0 125 0 0 70 0 70 -195 0 -195
0 0 -315z"/>
            </g>
        </svg> </span><span className="sr-only">Home page</span></>
    )
}
export default Banner;