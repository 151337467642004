import {Link} from "react-router-dom";
import getMostRecentNewsLink from "../../logic/getMostRecentNewsLink";

const Navbar = () => {
    return (
        <div className="navbar bg-base-300 w-full">
            <div className="flex flex-row justify-around w-full fridge">
                <div className="" >
                    <Link to={getMostRecentNewsLink()}
                          className="text-lg font-bold btn btn-primary ">
                        News
                    </Link>
                </div>
                <div className="">
                    <Link to="/tierList" className="text-lg font-bold btn btn-primary">
                        Tier List
                    </Link>
                </div>
                <div>
                    <Link to="/guides" className="text-lg font-bold btn btn-primary">
                        Guides
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
