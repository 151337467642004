import iron_man_small_portrait from "../../../assets/images/portrait/small/ironman_portrait.webp";
import iron_man_medium_portrait from "../../../assets/images/portrait/medium/ironman_portrait.webp";
import iron_man_large_portrait from "../../../assets/images/portrait/large/ironman_portrait.webp";
import {ReactComponent as IronManPrimary} from "../../../assets/images/abilities/ironman/repulsor-blast.svg";
import {ReactComponent as IronManLShift} from "../../../assets/images/abilities/ironman/hyper-velocity.svg";
import {ReactComponent as IronManQ} from "../../../assets/images/abilities/ironman/invincible-pulse-cannon.svg";
import {ReactComponent as IronManE} from "../../../assets/images/abilities/ironman/armor-overdrive.svg";
import {ReactComponent as IronManF} from "../../../assets/images/abilities/ironman/micro-missile-barrage.svg";
import {ReactComponent as IronManSecondary} from "../../../assets/images/abilities/ironman/unibeam.svg";

const IronManData = {
    name: "Iron Man",
    small_portrait: iron_man_small_portrait,
    medium_portrait: iron_man_medium_portrait,
    large_portrait: iron_man_large_portrait,
    link: "/guides/ironman",
    realName: "Tony Stark",
    affiliations: ["Avengers"],
    difficulty: 2,
    primary: "Repulsor Blast",
    primaryIcon: <IronManPrimary/>,
    primaryDescription: "Fires a blast of repulsor energy, launching a slow moving projectile that explodes on impact",
    Q: "Invincible Pulse Cannon",
    QIcon: <IronManQ/>,
    LShift: "Hyper-velocity",
    LShiftIcon: <IronManLShift/>,
    E: "Armor Overdrive",
    EIcon: <IronManE/>,
    EDescription: "Empowers Iron Man's armor, increasing the damage of Repulsor Blast and Unibeam for a short duration",
    F: "Micro-Missile Barrage",
    FIcon: <IronManF/>,
    secondary: "Unibeam",
    secondaryIcon: <IronManSecondary/>,
    secondaryDescription: "Fires a powerful beam of energy that deals damage over time",
};
export default IronManData;
