import peni_parker_small_portrait from "../../../assets/images/portrait/small/peniparker_portrait.webp";
import peni_parker_medium_portrait from "../../../assets/images/portrait/medium/peniparker_portrait.webp";
import peni_parker_large_portrait from "../../../assets/images/portrait/large/peniparker_portrait.webp";import { ReactComponent as PeniParkerPrimary } from "../../../assets/images/abilities/peniparker/cyber-web-cluster.svg";
import { ReactComponent as PeniParkerQ } from "../../../assets/images/abilities/peniparker/spider-sweeper.svg";
import { ReactComponent as PeniParkerE } from "../../../assets/images/abilities/peniparker/arachno-mine.svg";
import { ReactComponent as PeniParkerF } from "../../../assets/images/abilities/peniparker/cyber-bond.svg";
import { ReactComponent as PeniParkerSpace } from "../../../assets/images/abilities/peniparker/wall-crawl.svg";
import { ReactComponent as PeniParkerLShift } from "../../../assets/images/abilities/peniparker/bionic-spider-nest.svg";
import { ReactComponent as PeniParkerSecondary } from "../../../assets/images/abilities/peniparker/cyber-web-snare.svg";
const PeniParkerData = {
    name: "Peni Parker",
    small_portrait: peni_parker_small_portrait,
    medium_portrait: peni_parker_medium_portrait,
    large_portrait: peni_parker_large_portrait,
    link: "/guides/peniparker",
    affiliations: ["Spider-Verse"],
    difficulty: 3,
    primary: "Cyber-Web Cluster",
    primaryIcon: <PeniParkerPrimary />,
    Q: "Spider-Sweeper",
    QIcon: <PeniParkerQ />,
    LShift: "Bionic Spider-Nest",
    LShiftIcon: <PeniParkerLShift />,
    E: "Arachno-Mine",
    EIcon: <PeniParkerE />,
    F: "Cyber-Bond",
    FIcon: <PeniParkerF />,
    space: "Wall Crawl",
    spaceIcon: <PeniParkerSpace />,
    secondary: "Cyber-Web Snare",
    secondaryIcon: <PeniParkerSecondary />

};
export default PeniParkerData;