import punisher_small_portrait from "../../../assets/images/portrait/small/punisher_portrait.webp";
import punisher_medium_portrait from "../../../assets/images/portrait/medium/punisher_portrait.webp";
import punisher_large_portrait from "../../../assets/images/portrait/large/punisher_portrait.webp";


// import { ReactComponent as PunisherPrimary } from "../../../assets/images/abilities/punisher/adjudication.svg";
import { ReactComponent as PunisherLShift } from "../../../assets/images/abilities/punisher/vantage-connection.svg";
import { ReactComponent as PunisherQ } from "../../../assets/images/abilities/punisher/final-judgement.svg";
import { ReactComponent as PunisherE } from "../../../assets/images/abilities/punisher/culling-turret.svg";
import { ReactComponent as PunisherSecondary } from "../../../assets/images/abilities/punisher/scourge-grenade.svg";
// import { ReactComponent as PunisherPassive } from "../../../assets/images/abilities/punisher/warriors-gaze.svg";

const PunisherData = {
    name: "Punisher",
    small_portrait: punisher_small_portrait,
    medium_portrait: punisher_medium_portrait,
    large_portrait: punisher_large_portrait,
    link: "/guides/punisher",
    realName: "Frank Castle",
    difficulty: 1,
    primary: "Adjudication",
    primary2: "Deliverance",
    Q: "Final Judgement",
    QIcon: <PunisherQ />,
    LShift: "Vantage Connection",
    LShiftIcon: <PunisherLShift />,
    E: "Culling Turret",
    EIcon: <PunisherE />,
    secondary: "Scourge Grenade",
    secondaryIcon: <PunisherSecondary />,
    passive: "Warrior's Gaze"

};
export default PunisherData;